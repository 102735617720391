<template>
  <b-col
    class="d-flex align-items-center text-center auth-bg px-2 p-lg-5"
    lg="4"
    style="position: relative"
  >
    <b-col
      class="px-xl-3 mx-auto text-center"
      lg="12"
      md="6"
      sm="8"
    >
      <div class="mx-auto px-5">
        <b-img :src="logo" alt="Logo de Monica Groups" fluid />
      </div>

      <b-card-title class="text-center h1">Bienvenue sur Monica</b-card-title>

      <b-card-text id="sub-title" class="mb-2"
        >Veuillez vous connecter à votre compte pour commencer
        l'aventure</b-card-text
      >

      <validation-observer ref="loginForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="login">
          <div id="login-group">
            <b-form-group label="Courriel" label-for="login-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
                vid="email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@example.com"
                  class="py-2"
                  required
                />
                <small class="text-danger">{{
                  errors[0] ? "Adresse courriel invalide" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <b-button
            block
            class="login-button mt-2"
            type="submit"
            variant="primary"
          >
            <b-spinner
              v-if="is_loading"
              type="grow"
              class="loading-dot"
              label="Busy"
            ></b-spinner>
            <span>Se connecter</span>
          </b-button>
        </b-form>
      </validation-observer>

      <contact-us class="mt-5" />

    </b-col>
    <div class="mx-auto" style="position: absolute; bottom: 0; left: 50%; transform: translate(-50%, -50%);">
      <small
        class="text-center text-muted mt-2"
      >
        Propulsé par Monica Groups<br />
        © 2020-{{ new Date().getFullYear() }}
      </small>
    </div>
  </b-col>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import { email, required } from "@validations";
import ContactUs from "@authnz/components/ContactUs";
import authConfig from "@/configuration/authentication-config";
import { ref } from "@vue/composition-api";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { useLocalisation } from "@/shared/composables/use-localisation";
import Tenant from "@/plugins/tenant";

export default {
  name: "login-form",
  components: {
    BCardText,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCol,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    ContactUs,
    BSpinner,
  },
  setup() {
    const { logger, httpClient, router } = useApplicationContext();
    let is_loading = ref();
    const userEmail = ref("");
    const { t } = useLocalisation();
    const tenantId = Tenant.resolveTenantId();

    let logo = require("@/assets/images/logo/Logo_MonicaGroups_Couleurs_RVB.svg");

    const login = async () => {
      try {
        is_loading.value = true;
        logger.debug(`Authenticating user ${userEmail.value}`);
        let authInitiationResponse = await httpClient.post(
          `${authConfig.authEndpoint}`,
          { username: userEmail.value }
        );
        const authRedirectionUrl = authInitiationResponse.request.responseURL;

        logger.debug(
          `User ${userEmail.value} is redirected to ${authRedirectionUrl}`
        );
        window.location.href = authRedirectionUrl;
      } catch (error) {
        is_loading.value = false;
        logger.error(
          `Could not initiate authentication for user ${userEmail.value}`,
          error
        );
        router.push("/auth/error");
      }
    };

    return {
      logo,
      userEmail,
      required,
      email,
      login,
      is_loading,
      tenantId,
      t,
    };
  },
};
</script>

<style scoped>
.loading-dot {
  max-width: 15px;
  max-height: 15px;
  margin-right: 5px;
}

#login-email {
  text-align: left !important;
}

h1,
.h1 {
  font-size: 2.2rem !important;
}

#sub-title {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.8rem;
}

#login-group {
  text-align: left !important;
}

#login-group label[for="login-email"] {
  font-size: 1.2rem !important;
}

.login-button {
  background-color: #273c50 !important;
  border-color: #273c50 !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.login-button:hover {
  background-color: #34a0a4 !important;
  border-color: #34a0a4 !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  transition: all 0.25s ease;
  box-shadow: initial !important;
}
</style>
